import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

const PersonalInformation = Loadable(
  lazy(() => import("views/common/profile/personalInformation"))
);
const SocialLinks = Loadable(
  lazy(() => import("views/common/profile/socialLinks"))
);
const SecurityAndNotifications = Loadable(
  lazy(() => import('views/common/profile/changePassword'))
);
const BillingAndSubscriptions = Loadable(
  lazy(() => import('views/common/profile/billingAndSubscriptions'))
);

const ProfileRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/p/personal-information',
      element: <PersonalInformation />,
    },
    {
      path: '/p/social-links',
      element: <SocialLinks />,
    },
    {
      path: '/p/change-password',
      element: <SecurityAndNotifications />,
    },
    {
      path: '/p/billing-and-subscriptions',
      element: <BillingAndSubscriptions />,
    },
  ],
};

export default ProfileRoutes;

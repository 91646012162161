import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";

import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";

// assets

// styles
const Main = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.background.bgMain,
  boxShadow: "5px 5px 10px 0px #00000040",
  margin: theme.spacing(11, 0, 0),
  borderRadius: theme.spacing(2),
  width: "100%",
  flexGrow: 1,
  height: "calc(95vh - 88px)",
  overflow: "auto",
  padding: theme.spacing(3, 2),
  [theme.breakpoints.down("md")]: {
    width: "100vw",
    padding: theme.spacing(2),
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const WrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    gap: 0, // Remove gap between elements
    height: "100vh",
    with: "100%",
    padding: theme.spacing(0),
  },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const StepFormLayout = () => {
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { drawerType, container, layout } = useConfig();

  useEffect(() => {
    if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
      dispatch(openDrawer(true));
    } else {
      dispatch(openDrawer(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerType]);

  useEffect(() => {
    if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
      dispatch(openDrawer(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matchDownMd) {
      dispatch(openDrawer(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const header = useMemo(
    () => (
      <Toolbar
        sx={{
          p: "0px !important",
          marginBottom: "0",
          backgroundColor: theme.palette.background.bgMain,
        }}
      >
        <Header />
      </Toolbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layout, matchDownMd]
  );

  return (
    <Box
      sx={{
        display: "flex",
        background: theme.palette.background.bgMain,
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      {/* header */}
      <AppBar position="fixed" color="inherit" elevation={0}>
        {header}
      </AppBar>

      <WrapperBox>
        {/* drawer */}
        <Sidebar />

        {/* main content */}
        <Main theme={theme} open={drawerOpen} layout={layout}>
          <Outlet />
        </Main>
      </WrapperBox>
    </Box>
  );
};

export default StepFormLayout;

import { Box, Stack, styled, Typography, Divider } from "@mui/material";
import PartnerLog from "assets/images/partner/p1.png";

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: "50px 0",
  background: theme.palette.background.paper,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: "1000px",
  width: "100%",
  padding: "0 20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const PartnerTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: "40.05px",
  letterSpacing: "-0.04em",
}));

const ContactTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: "40.05px",
  letterSpacing: "-0.04em",
}));

const OurPartners = () => {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <Stack
          alignItems={{ xs: "center", md: "flex-start" }}
          justifyContent={"center"}
        >
          <PartnerTitle>Our partners</PartnerTitle>
          <Box
            component={"img"}
            src={PartnerLog}
            alt="Partner Logo"
            height={140}
          />
        </Stack>

        <Divider orientation={"vertical"} flexItem sx={{ mx: 4 }} />

        <Stack
          alignItems={{ xs: "center", md: "flex-start" }}
          justifyContent={"center"}
        >
          <ContactTypography>To partner with us contact</ContactTypography>
          <ContactTypography>partners@produculator.com</ContactTypography>
        </Stack>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default OurPartners;

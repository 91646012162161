// material-ui
import { Box, List, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  ...theme.typography.h4,

  ":hover": {
    borderRadius: theme.spacing(0.5),
  },
}));

// ==============================|| SEARCH INPUT ||============================== //
const menuItems = [
  { label: "About us", path: "/home" },
  { label: "Our Client", path: "/home" },
  { label: "Support", path: "/home" },
  { label: "Resources", path: "/home" },
  { label: "Company", path: "/home" },
];

const HeaderLinkSection = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const renderMenuItems = () =>
    menuItems.map((item) => (
      <Box
        key={item.label}
        style={{ textDecoration: "none" }}
        onClick={() => window.open(item.path)}
      >
        <MenuItemWrapper>{item.label}</MenuItemWrapper>
      </Box>
    ));

  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        flexGrow: 1,
        display: matchDownMd ? "none" : "flex", // Hide on small screens
        spacing: { xs: 1.5, md: 2.5 },
      }}
    >
      <List sx={{ display: "flex", gap: 3 }}>{renderMenuItems()}</List>
    </Stack>
  );
};

export default HeaderLinkSection;

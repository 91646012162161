// material-ui
import {
  Avatar,
  Box,
  MenuItem,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// project imports
import useConfig from "hooks/useConfig";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import LogoSection from "ui-component/logoSection";
// assets
import { IconMenu2 } from "@tabler/icons-react";
import HelpIcon from "assets/images/svg/help.svg";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.9) !important", // semi-transparent black
  },
}));
const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),

  ":hover": {
    borderRadius: theme.spacing(0.5),
  },
}));

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      p={2}
      sx={{
        width: "100%",
      }}
    >
      {/* logo & toggler button */}
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {matchDownMd && (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              display: { xs: "flex", md: "none" },
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.background.paper,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        )}
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ flexGrow: 1 }}
      >
        <Box sx={{ flexGrow: 1 }} />

        <MenuItemWrapper>
          <Box component={"img"} src={HelpIcon} alt="HelpIcon" />
          <BootstrapTooltip title="Please feel free to reach out to us at support@prodculator if you are facing an issue.">
            <Typography variant="h4" fontWeight={500}>
              Need help?
            </Typography>
          </BootstrapTooltip>{" "}
        </MenuItemWrapper>
      </Stack>
    </Stack>
  );
};

export default Header;
